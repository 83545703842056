<template lang='pug'>
div.form__wrapper
  img.logo(src='../assets/logo.png')
  h1(v-if='!submitted') Invalid Activation Code
  h1(v-else-if='error') No Record Found
  h1(v-else) Confirmation Email Sent
  div(v-if='!submitted')
    p The activation code provided has expired or was invalid. If you've already signed up, enter your email and we'll send you a link to complete your registration. Otherwise, click the link below to create an account.
    el-form(:model='form', :rules='rules', ref='resendEmailForm', v-loading='loading', @submit.prevent.native='resendEmail')
      el-form-item(prop='email')
        el-input(v-model='form.email', placeholder='Email', autofocus)
      el-form-item
        el-button.submit(type='primary', @click='resendEmail') Resend confirmation email

    router-link(to='/create') 
      el-button(type='text') Create an account

  div(v-else-if='error')
    p No account was found with the email address provided. If you haven't signed up yet, click the link below. If you think you're receiving this message in error, please contact customer support.

    el-form
      router-link(to='/create')
        el-button(type='primary', style='width: 100%') Create an account

  div(v-else)
    p Check your 
      span.bold {{ form.email.substring(form.email.lastIndexOf('@')) }} 
      | email address for an email from us. The email will contain a link to confirm your registration that will be valid for one hour. Once you've clicked on that link, you'll be able to sign in.
    p Sometimes spam filters don't like us. If you don't see a verification email, try checking your spam folder. Otherwise, please feel free to contact customer support.
</template>

<script>
import api from '../api'

export default {
  name: 'InvalidCode',
  data () {
    return {
      loading: false,
      submitted: false,
      error: false,
      form: {
        email: null
      },
      rules: {
        email: [
          { required: true, message: 'Enter an email address', trigger: 'submit' },
          { type: 'email', message: 'Enter a valid email address', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    resendEmail () {
      this.$refs.resendEmailForm.validate((valid) => {
        if (valid) {
          this.loading = true
          api.post('resend', this.form).then((res) => {
            this.loading = false
            this.submitted = true
          }).catch(_ => {
            this.loading = false
            this.submitted = true
            this.error = true
          })
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../scss/common';

h1 {
  margin-bottom: 0;
}

.el-form {
  margin-top: 3rem;
}

.submit {
  margin-top: 0;
}
</style>